import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import {
  Box,
  Link,
  Button,
  IconButton,
  FormLabel,
  FormControl,
  FormErrorMessage,
  InputRightElement,
  InputGroup,
  useToast,
} from '@chakra-ui/react'
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs'
import { useLocation, navigate } from '@reach/router'
import { Link as RouterLink } from 'gatsby'
import { parse } from 'query-string'

import Input from '~components/shared/input'
import { useAuth } from '~context/auth'

const LoginForm: React.FC = () => {
  const [show, setShow] = useState(false)

  const toast = useToast()
  const { login } = useAuth()
  const { search } = useLocation()
  const { redirect } = parse(search) as { redirect: string }

  const handleLogin = async (email: string, password: string) => {
    const { success, errorMessage } = await login(email, password)

    if (success) navigate(redirect)
    else {
      toast.closeAll()
      toast({
        isClosable: true,
        status: 'error',
        variant: 'subtle',
        position: 'bottom',
        title: 'Authentication Error',
        description: errorMessage,
      })
    }
  }

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validateOnChange={true}
      validate={values => {
        const errors = {
          email: '',
          password: '',
        }

        if (!values.email) errors.email = 'Email is required'
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email))
          errors.email = 'Invalid email address'
        if (!values.password) errors.password = 'Password is required'

        if (Object.values(errors).some(error => !!error)) return errors
        return {}
      }}
      onSubmit={(values, actions) => {
        handleLogin(values.email, values.password).then(() => {
          actions.setSubmitting(false)
        })
      }}
    >
      {({
        values,
        errors,
        touched,
        isValid,
        dirty,
        handleBlur,
        handleChange,
        isSubmitting,
      }) => (
        <Form>
          <FormControl id="email" isInvalid={touched.email && !!errors.email}>
            <FormLabel fontWeight="semibold">Email</FormLabel>
            <Input
              placeholder="Email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormErrorMessage>{errors.email}</FormErrorMessage>
          </FormControl>
          <FormControl
            id="password"
            isInvalid={touched.password && !!errors.password}
          >
            <FormLabel fontWeight="semibold" mt={4}>
              Password
            </FormLabel>
            <InputGroup variant="filled">
              <Input
                placeholder="Password"
                type={show ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputRightElement>
                <IconButton
                  variant="ghost"
                  aria-label="Password toggle"
                  onClick={() => setShow(!show)}
                  icon={
                    show ? (
                      <BsEyeSlashFill size="20px" />
                    ) : (
                      <BsEyeFill size="20px" />
                    )
                  }
                />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors.password}</FormErrorMessage>
          </FormControl>
          <Box textAlign="right" my="24px">
            <Link as={RouterLink} to="/" fontSize="sm">
              Forgot password
            </Link>
          </Box>
          <Button
            size="lg"
            rounded="lg"
            isFullWidth
            type="submit"
            colorScheme="brand"
            isLoading={isSubmitting}
            disabled={!isValid || !dirty}
          >
            Log in
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default LoginForm
