import React, { useEffect } from 'react'
import {
  Box,
  Text,
  VStack,
  ScaleFade,
  useColorModeValue,
} from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import { navigate } from '@reach/router'

import Seo from '~components/shared/seo'
import Logo from '~components/layout/logofull'
import LoginForm from '~components/pages/login/login-form'
import { useAuth } from '~context/auth'

const LoginPage: React.FC = () => {
  const { isAuthenticated } = useAuth()
  const bgColor = useColorModeValue('gray.50', 'gray.800')

  useEffect(() => {
    if (isAuthenticated) navigate('/', { replace: true })
  }, [isAuthenticated])

  return (
    <>
      <Seo title="Log in" />
      <Box h="100vh" w="100vw">
        <Box
          h="90%"
          display={['initial', 'initial', 'initial', 'grid']}
          gridTemplateColumns="repeat(2, 1fr)"
        >
          <Box
            px={[4, 0]}
            h="100%"
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box w="400px" maxW="100%">
              <ScaleFade in={true}>
                <Box px={[4, 6]} py="40px" bgColor={bgColor} rounded="2xl">
                  <VStack mb="40px">
                    <Logo mb={6} />
                    <Text fontSize={['xl', '2xl']} fontWeight="bold">
                      Log in to your partners account
                    </Text>
                  </VStack>
                  <LoginForm />
                </Box>
              </ScaleFade>
            </Box>
          </Box>
          <Box h="100%" w="100%" display={['none', 'none', 'none', 'initial']}>
            <Box h="100%" overflow="hidden" borderBottomLeftRadius="24px">
              <StaticImage
                layout="fullWidth"
                placeholder="blurred"
                alt="Festival pass login image"
                src="../images/background/festival.jpg"
                style={{ height: '100%', width: '100%', objectFit: 'cover' }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default LoginPage
